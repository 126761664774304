@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css';
@import "@angular/material/prebuilt-themes/indigo-pink.css";

.cdk-overlay-container{ z-index: 1200 !important; }  

.modal {
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}
.modal__header {
    height: 50px;
}
.modal__footer  {
    height: 50px;
}

.modal__content,
.modal__footer {
    position: absolute;
    width: 100%;
}
.modal__content {
    bottom: 0px;
    top: 50px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.modal__footer {
    bottom: 0;
}

.sfondoNav { 
    background: linear-gradient(6deg, rgb(0, 100, 192), rgb(0, 200, 255));
    /* background-color: #16A0CE; */
    color: white;
}

.center_spinner {
    position: fixed;
    z-index: 999;
    height: 2rem;
    width: 2rem;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

.control-full-width {
    width: 100%;
}

.toolbar-mat-icon {
    font-size: 30px;
    height: 56px;
    width: 56px;
    line-height: 56px;
    color: white;
}

.mb_maxwidth {
    max-width: 768px;
}